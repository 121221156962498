import React from 'react';
import Faq from 'react-faq-component';

import './Faqs.css';

import Section from '../../components/section';
import FAQ_CONFIG from '../../configs/faq-config';

function FaqsView() {
	return (
		<Section name="Faqs">
			<h2 className="title text-center">{FAQ_CONFIG.title}</h2>
			<Faq data={FAQ_CONFIG.data} styles={FAQ_CONFIG.styles} />
		</Section>
	);
}

export default FaqsView;
