import React from 'react';
import './Footer.css';

import Socials from '../../components/socials';

const year = new Date().getFullYear();

function FooterView() {
	return (
		<div className="Footer">
			<Socials />
			<div className="copyright">{year} BountyBotz</div>
		</div>
	);
}

export default FooterView;
