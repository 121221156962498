import React from "react";
import "./App.css";

import CSS_VAR_OVERRIDES from "../../configs/site-config";
import CarouselView from "../carousel";
import FaqsView from "../faqs";
import FooterView from "../footer";
import HeroView from "../hero";
//import MintingView from '../minting';
import RoadmapView from "../roadmap";
import TeamView from "../team";
import LegendView from "../legends";
import { ethers, BigNumber } from "ethers";
import CollectionConfig from "../../smart-contract/config/CollectionConfig";

import Mint from "../mint";

function getDynamicCssVariables(siteConfig) {
  const overrides = {};
  siteConfig.map((config) => (overrides[config.name] = config.value));
  return overrides;
}

class App extends React.Component {
  render() {
    const variableOverrides = getDynamicCssVariables(CSS_VAR_OVERRIDES);

    const showContract = window.location.search === "?contract";

    return (
      <div className="App" style={variableOverrides}>
        <Mint />
        <LegendView />
        <RoadmapView />
        <CarouselView />
        <FaqsView />
        <TeamView />
        <FooterView />
      </div>
    );
  }
}

export default App;
