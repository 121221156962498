import React from "react";
import Dapp from "../../scripts/react/Dapp";
import styled from "styled-components";
import { keyframes } from "styled-components";
import ARTWORK_CONFIG from "../../configs/artwork-config";
import Particles from "react-tsparticles";
import particlesConfig from "./particles";
import { ReactComponent as OpenSeaSvg } from "../../images/logos/opensea.svg";
import { ReactComponent as TwitterSvg } from "../../images/logos/twitter.svg";

const Container = styled.div`
  padding: 24px;
  z-index: 2;
  
  a {
    color: #5FCEE8;
  }

  @media (max-width: 480px) {
    padding: 24px 0;
  }
}
`;

const DappContainer = styled.div`
  width: 50%;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    width: 90%;
    margin: 0;
  }
`;

const ViewOnContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  max-width: 30%;

  @media (max-width: 480px) {
    max-width: 90%;
    margin-top: 16px;
    text-align: center;
    margin-left: 0;
  }
`;

const Gallery = styled.div`
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 6px;

  @media (max-width: 480px) {
    margin-top: 16px;
  }

  img {
    /* width: 128px; */
  }
`;

const Header = styled.div`
  font-size: 64px;
  width: 100%;
  font-family: var(--font-family-title);
  font-weight: 900;
  line-height: 1.2;
  text-shadow: 3px 3px var(--black);
  text-transform: uppercase;
  letter-spacing: -2.4px;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 480px) {
    font-size: 36px;
  }
`;

const MintBackgroundAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Background = styled.div`
  background: linear-gradient(-45deg, #1e1e1e, #1f1f1f, #000000, #010101);
  background-size: 400% 400%;
  animation: ${MintBackgroundAnimation} 40s ease infinite;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 480px) {
    height: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10vh;
  }
}
`;

const ConnectButton = styled.a`
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin: 16px auto;
  width: 200px;

  span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    width: 100%;
    height: 100%;
    transition: 300ms;
    color: #ffffff;
  }

  &:active,
  &:hover {
    outline: 0;
  }

  &:hover {
    span {
      background: none;
    }
  }
`;

function Mint() {
  const renderImage = (image) => {
    return <img src={image} />;
  };

  return (
    <Background>
      {/* <Particles
        style={{ zIndex: 1 }}
        canvasClassName="particles"
        options={particlesConfig}
      /> */}
      <Container>
        <ContentContainer>
          <DappContainer>
            <Header>
              Welcome to
              <br />
              BountyBotz!
            </Header>
            {window.ethereum ? (
              <Dapp />
            ) : (
              <ConnectButton target="_blank" href="https://metamask.io">
                <span>Get Metamask</span>
              </ConnectButton>
            )}
            <ViewOnContainer>
              View on
              {renderSocial({
                name: "OpenSea",
                // TODO: update to correct prod link.
                link: "https://opensea.io/collection/bountybotz",
                component: OpenSeaSvg,
                display: true,
              })}
              {renderSocial({
                name: "Twitter",
                link: "https://twitter.com/BountyBotz",
                component: TwitterSvg,
                display: true,
              })}
            </ViewOnContainer>
          </DappContainer>
          <Details>
            <div>
              BOUNTYBOTZ MINT REWARD // <br />
              <br />
              To celebrate the launch, 1 winner will be randomly chosen from the
              first 469 unique holders. <br />
              <br />
              The winner will get to choose one Legendary to receive as a free
              airdrop.
              <br /> <br />
              Details will be posted on&nbsp;
              <a href="https://twitter.com/BountyBotz">
                https://twitter.com/BountyBotz
              </a>
              .
            </div>
            <Gallery>{ARTWORK_CONFIG.images.map(renderImage)}</Gallery>
          </Details>
        </ContentContainer>
      </Container>
    </Background>
  );
}

export default Mint;

function renderSocial(data) {
  const Component = data.component;
  if (!data.display) return null;
  return (
    <div className="link" key={data.name}>
      <a
        href={data.link}
        aria-label={data.name}
        rel="noreferrer"
        target="_blank"
      >
        {<Component />}
      </a>
    </div>
  );
}
