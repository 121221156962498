import React from 'react';
import './Section.css';

function Section({ name, children, style = {} }) {
	const classList = [name, 'section'];
	return (
		<div className="section-wrapper" style={style}>
			<div className={classList.join(' ')}>{children}</div>
		</div>
	);
}

export default Section;
