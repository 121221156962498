import { ReactComponent as TwitterSvg } from '../images/logos/twitter.svg';
import { ReactComponent as DiscordSvg } from '../images/logos/discord.svg';
import { ReactComponent as OpenSeaSvg } from '../images/logos/opensea.svg';

const socialsConfig = [
	{
		name: 'Twitter',
		link: 'https://twitter.com/BountyBotz',
		component: TwitterSvg,
		display: true,
	},
	{
		name: 'Discord',
		link: 'https://discord.com/invite/yRCeNuuw',
		component: DiscordSvg,
		display: false,
	},
	{
		name: 'OpenSea',
		// TODO: update to correct prod link.
		link: 'https://testnets.opensea.io/collection/bountybotz-mint-pass',
		component: OpenSeaSvg,
		display: false,
	},
];

export default socialsConfig;
