import AlexNft from "../images/nfts/nft-alex.webp";
import CurtisNft from "../images/nfts/nft-curtis.webp";
import FilipeNft from "../images/nfts/nft-filipe.webp";
import FelipeNft from "../images/nfts/nft-felipe.webp";
import DimaNft from "../images/nfts/nft-dima.webp";
import DamonNft from "../images/nfts/nft-damon.webp";
import { Linkedin, Instagram } from "react-feather";
import { ReactComponent as TwitterSvg } from "../images/logos/twitter.svg";

import AlexTeam from '../images/portraits/Alex-Team.png'
import CurtisTeam from '../images/portraits/Curtis-Team.png'
import DimaTeam from '../images/portraits/Dima-Team.png'
import FilipeTeam from '../images/portraits/Filipe-Team.png'
import Daem from '../images/portraits/daem.png'

const teamConfig = {
  title: "The Team",
  data: [
    {
      name: "Alex",
      title: "Founder",
      altText: "Alex's NFT",
      image: AlexTeam,
      classList: "m-b-28",
      description:
        "BountyBotz Founder. Oversees Project Roadmap, Art, and Engineering. Currently a Data Scientist at Tiktok, previously spent 5 years at Amazon in Analytics/Engineering.",
      socials: [
        {
          name: "Twitter",
          link: "https://twitter.com/BountyBotz",
          component: TwitterSvg,
          display: true,
        },
        //        {
        //          name: "Instagram",
        //          link: "https://instagram.com/dimmadog",
        //          component: Instagram,
        //          display: true,
        //        },
        {
          name: "Linkedin",
          link: "https://www.linkedin.com/in/alexzhouk/",
          component: Linkedin,
          display: true,
        },
      ],
    },
    {
      name: "Damon",
      title: "Marketing",
      altText: "Damon's",
      image: Daem,
      classList: "m-b-28",
      description:
        "BountyBotz Advisor for Twitter Marketing. Currently runs Elevate Marketing Agency.",
      socials: [
        {
          name: "Twitter",
          link: "https://twitter.com/degenIife",
          component: TwitterSvg,
          display: true,
        },
        //        {
        //          name: "Instagram",
        //          link: "https://instagram.com/dimmadog",
        //          component: Instagram,
        //          display: true,
        //        },
        //        {
        //          name: "Linkedin",
        //          link: "https://linkedin.com/dimitryvinogradov",
        //          component: Linkedin,
        //          display: true,
        //        },
      ],
    },
    {
      name: "Filipe",
      title: "Lead Artist",
      altText: "Filipe's NFT",
      image: FilipeTeam,
      classList: "m-b-28",
      description:
        "Concept Artist / Lead Illustrator for BountyBotz art. Previously spent 5 years as an Instructor at the School of Art, Games, and Animation (SAGA).",
      socials: [
        //        {
        //          name: "Twitter",
        //          link: "https://twitter.com/BountyBotz",
        //          component: TwitterSvg,
        //          display: true,
        //        },
        {
          name: "Instagram",
          link: "https://www.instagram.com/filipevasques.art/",
          component: Instagram,
          display: true,
        },
        {
          name: "Linkedin",
          link: "https://www.linkedin.com/in/filipe-vasques-0409a619a/",
          component: Linkedin,
          display: true,
        },
      ],
    },
    {
      name: "Felipe",
      title: "Support Artist",
      altText: "Felipe's NFT",
      image: FelipeNft,
      classList: "m-b-28",
      description: "Colorist / Illustrator for BountyBotz art.",
      //      socials: [
      //        {
      //          name: "Twitter",
      //          link: "https://twitter.com/BountyBotz",
      //          component: TwitterSvg,
      //          display: false,
      //        },
      //        {
      //          name: "Instagram",
      //          link: "https://instagram.com/dimmadog",
      //          component: Instagram,
      //          display: false,
      //        },
      //        {
      //          name: "Linkedin",
      //          link: "https://linkedin.com/dimitryvinogradov",
      //          component: Linkedin,
      //          display: false,
      //        },
      //      ],
    },
    {
      name: "Curtis",
      title: "Software Engineer",
      altText: "Curtis' NFT",
      image: CurtisTeam,
      classList: "m-b-28",
      description:
        "BountyBotz Website Engineer. Currently a Software Engineer at Google.",
      socials: [
        {
          name: "Twitter",
          link: "https://twitter.com/BountyBotz",
          component: TwitterSvg,
          display: false,
        },
        {
          name: "Instagram",
          link: "https://instagram.com/dimmadog",
          component: Instagram,
          display: false,
        },
        {
          name: "Linkedin",
          link: "https://www.linkedin.com/in/crodg/",
          component: Linkedin,
          display: true,
        },
      ],
    },
    {
      name: "Dima",
      title: "Software Engineer",
      altText: "Dima",
      image: DimaTeam,
      classList: "m-b-28",
      description:
        "BountyBotz Website Engineer. Currently a Software Engineer at Raya.",
      socials: [
        {
          name: "Twitter",
          link: "https://twitter.com/BountyBotz",
          component: TwitterSvg,
          display: false,
        },
        {
          name: "Instagram",
          link: "https://instagram.com/dimmadog",
          component: Instagram,
          display: false,
        },
        {
          name: "Linkedin",
          link: "https://linkedin.com/dimitryvinogradov",
          component: Linkedin,
          display: true,
        },
      ],
    },
  ],
};

export default teamConfig;
