import React from 'react';
import './Roadmap.css';
import ROADMAP_CONFIG from '../../configs/roadmap-config';
import Section from '../../components/section';

function RoadmapView() {
	return (
		<Section className="Roadmap">
			<div className="text-center">
				<h2 className="roadmap-title title">{ROADMAP_CONFIG.title}</h2>
				{ROADMAP_CONFIG.content.map(RoadmapItem)}
			</div>
		</Section>
	);
}

function RoadmapItem({ title, content }) {
	return (
		<div className="roadmap-item" key={title}>
			<h3 className="title">{title}</h3>
			<p className="roadmap-text">{content}</p>
		</div>
	);
}

export default RoadmapView;
