import React from 'react';
import './Socials.css';
import SOCIALS_CONFIG from '../../configs/socials-config';

function renderSocial(data) {
	const Component = data.component;
	if (!data.display) return null;
	return (
		<div className="link" key={data.name}>
			<a
				href={data.link}
				aria-label={data.name}
				rel="noreferrer"
				target="_blank"
			>
				{<Component />}
			</a>
		</div>
	);
}

function Socials() {
	return <div className="Socials">{SOCIALS_CONFIG.map(renderSocial)}</div>;
}

export default Socials;
