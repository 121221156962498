import React from 'react';
import { isMobile } from 'react-device-detect';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import './Carousel.css';
import ARTWORK_CONFIG from '../../configs/artwork-config';

import Section from '../../components/section';

const carouselOptions = {
	type: 'loop',
	perPage: isMobile ? 1 : 3,
	focus: 'left',
	gap: '4px',
};

function renderSlide(image, i) {
	return (
		<SplideSlide key={i}>
			<img src={image} alt="Bounty Botz NFT" className="carousel-image" />
		</SplideSlide>
	);
}

function CarouselView() {
	return (
		<Section name="Carousel">
			<h2 className="title text-center">{ARTWORK_CONFIG.title}</h2>
			<div>
				<Splide options={carouselOptions}>
					{ARTWORK_CONFIG.images.map(renderSlide)}
				</Splide>
			</div>
		</Section>
	);
}

export default CarouselView;
