import React from "react";
import styled from "styled-components";
import "./TeamMember.css";
import { ReactComponent as OpenSeaSvg } from "../../images/logos/opensea.svg";
import { ReactComponent as TwitterSvg } from "../../images/logos/twitter.svg";

const Description = styled.div`
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Socials = styled.div`
  display: flex;
  margin-top: 8px;
  color: #ffffff;

  .link {
    margin-right: 16px;
    margin-left: 0;

    svg {
      color: #ffffff;
    }
  }
`;

function renderSocial(data) {
  const Component = data.component;
  if (!data.display) return null;
  return (
    <div className="link" key={data.name}>
      <a
        href={data.link}
        aria-label={data.name}
        rel="noreferrer"
        target="_blank"
      >
        {<Component />}
      </a>
    </div>
  );
}

function TeamMember({ name, title, children, socials, description }) {
  return (
    <div className="TeamMember">
      <div className="name-wrapper">
        <div>{children}</div>
        <h3 className="person-title">
          {name} ({title})
        </h3>
        <Description>{description}</Description>
        <Socials>{socials && socials.map(renderSocial)}</Socials>
      </div>
    </div>
  );
}

export default TeamMember;
