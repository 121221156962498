import React from "react";
import styled from "styled-components";

interface Props {
  userAddress: string | null;
  totalSupply: number;
  maxSupply: number;
  isPaused: boolean;
  isWhitelistMintEnabled: boolean;
  isUserInWhitelist: boolean;
  isSoldOut: boolean;
}

interface State {}

const defaultState: State = {};

const Note = styled.div`
  font-size: 12px;
  max-width: 50%;
`;

const SupplyContainer = styled.div`
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;

  span {
    font-weight: 300;
    font-size: 14px;
  }
`;

const SaleStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: 600;
  text-transform: uppercase;

  span {
    font-size: 14px;
    font-weight: 300;
    text-transform: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class CollectionStatus extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = defaultState;
  }

  private isSaleOpen(): boolean {
    return (
      (this.props.isWhitelistMintEnabled || !this.props.isPaused) &&
      !this.props.isSoldOut
    );
  }

  render() {
    return (
      <Container>
        {/* <div className="user-address">
            <span className="label">Wallet address:</span>
            <span className="address">{this.props.userAddress}</span>
          </div> */}

        <SupplyContainer>
          {this.props.totalSupply}/{this.props.maxSupply}
          <span className="label"> available</span>
        </SupplyContainer>

        <SaleStatusContainer>
          {this.isSaleOpen() ? (
            <>{this.props.isWhitelistMintEnabled ? "Whitelist only" : "Open"}</>
          ) : (
            "Closed"
          )}
          <span className="label">Sale status</span>
        </SaleStatusContainer>
        {this.props.isWhitelistMintEnabled && (
          <Note>
            Note: WL = Max of 3 mints per Wallet
          </Note>
        )}
      </Container>
    );
  }
}
