import Nftair from '../images/nfts/nft-air.webp';
import Nftdark from '../images/nfts/nft-dark.webp';
import Nftearth from '../images/nfts/nft-earth.webp';
import Nftethereal from '../images/nfts/nft-ethereal.webp';
import Nftfire from '../images/nfts/nft-fire.webp';
import Nftice from '../images/nfts/nft-ice.webp';
import Nftlight from '../images/nfts/nft-light.webp';
import Nftlightning from '../images/nfts/nft-lightning.webp';
import Nftplasma from '../images/nfts/nft-plasma.webp';
import Nftsmoke from '../images/nfts/nft-smoke.webp';
import Nftspirit from '../images/nfts/nft-spirit.webp';
import Nftstone from '../images/nfts/nft-stone.webp';
import Nftwater from '../images/nfts/nft-water.webp';
import Nftpoison from '../images/nfts/nft-poison.webp';
import Nftmetal from '../images/nfts/nft-metal.webp';
import Nftlava from '../images/nfts/nft-lava.webp';

const legendConfig = {
	title: 'Legendary BountyBotz for Auction',
	data: [
		{
			name: 'Air',
			//title: '',
			altText: 'Air NFT',
			image: Nftair,
			classList: 'm-b-28',
		},
		{
			name: 'Dark',
			//title: '',
			altText: 'Dark NFT',
			image: Nftdark,
			classList: 'm-b-28',
		},
		{
			name: 'Earth',
			//title: '',
			altText: 'Earth NFT',
			image: Nftearth,
			classList: 'm-b-28',
		},
		{
			name: 'Ethereal',
			//title: '',
			altText: 'Ethereal NFT',
			image: Nftethereal,
			classList: 'm-b-28',
		},
		{
			name: 'Fire',
			//title: '',
			altText: 'Fire NFT',
			image: Nftfire,
			classList: 'm-b-28',
		},
		{
			name: 'Ice',
			//title: '',
			altText: 'Ice NFT',
			image: Nftice,
			classList: 'm-b-28',
		},
		{
			name: 'Light',
			//title: '',
			altText: 'Light NFT',
			image: Nftlight,
			classList: 'm-b-28',
		},
		{
			name: 'Lightning',
			//title: '',
			altText: 'Lightning NFT',
			image: Nftlightning,
			classList: 'm-b-28',
		},
		{
			name: 'Plasma',
			//title: '',
			altText: 'Plasma NFT',
			image: Nftplasma,
			classList: 'm-b-28',
		},
		{
			name: 'Smoke',
			//title: '',
			altText: 'Smoke NFT',
			image: Nftsmoke,
			classList: 'm-b-28',
		},
		{
			name: 'Spirit',
			//title: '',
			altText: 'Spirit NFT',
			image: Nftspirit,
			classList: 'm-b-28',
		},
		{
			name: 'Stone',
			//title: '',
			altText: 'Stone NFT',
			image: Nftstone,
			classList: 'm-b-28',
		},
		{
			name: 'Water',
			//title: '',
			altText: 'Water NFT',
			image: Nftwater,
			classList: 'm-b-28',
		},
		{
			name: 'Poison',
			//title: '',
			altText: 'Poison NFT',
			image: Nftpoison,
			classList: 'm-b-28',
		},
		{
			name: 'Metal',
			//title: '',
			altText: 'Metal NFT',
			image: Nftmetal,
			classList: 'm-b-28',
		},
		{
			name: 'Lava',
			//title: '',
			altText: 'Lava NFT',
			image: Nftlava,
			classList: 'm-b-28',
		}, //
	],
};

export default legendConfig;
