import { utils, BigNumber } from "ethers";
import React from "react";
import NetworkConfigInterface from "../../smart-contract/lib/NetworkConfigInterface";
import styled from "styled-components";

interface Props {
  networkConfig: NetworkConfigInterface;
  maxSupply: number;
  totalSupply: number;
  tokenPrice: BigNumber;
  maxMintAmountPerTx: number;
  isPaused: boolean;
  loading: boolean;
  isWhitelistMintEnabled: boolean;
  isUserInWhitelist: boolean;
  mintTokens(mintAmount: number): Promise<void>;
  whitelistMintTokens(mintAmount: number): Promise<void>;
}

interface State {
  mintAmount: number;
}

const defaultState: State = {
  mintAmount: 1,
};

const MintButton = styled.button`
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin-left: 12px;

  @media (max-width: 480px) { 
    margin-left: 0;
    font-size: 32px;
  }

  span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    width: 100%;
    height: 100%;
    transition: 300ms;

    @media (max-width: 480px) {
      padding: 24px 48px;
    }
  }

  &:active,
  &:hover {
    outline: 0;
  }

  &:hover {
    span {
      background: none;
    }
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

const AmountActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  @media (max-width: 480px) {
    margin-right: 0;
    margin-top: 16px;
  }
`;

const DecrementButton = styled.button`
  width: 35px;
  height: 35px;
  background: #05062b;
  cursor: pointer;
  border: 1px solid #2a3d61;
  outline: 0;
  position: relative;

  @media (max-width: 480px) {
    width: 48px;
    height: 48px;
  }

  &:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #ffffff;
    top: 50%;
    left: 50%;
  }

  &:hover {
    background-color: #5fcee8;
    transition: 0.2s;
    &:before,
    &:after {
      background: #fff;
      transition: 0.2s;
    }
  }
`;

const IncrementButton = styled(DecrementButton)`
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    height: 50%;
    width: 4px;
  }
`;

const TotalToMint = styled.div`
  padding: 0 12px;
  font-size: 18px;
`;

const PriceContainer = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const Price = styled.span`
  font-size: 24px;
  text-decoration: underline;
`;

const CannotMint = styled.div`
  margin-bottom: 16px;
  color: #e8bc40;
`;

export default class MintWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = defaultState;
  }

  private canMint(): boolean {
    return !this.props.isPaused || this.canWhitelistMint();
  }

  private canWhitelistMint(): boolean {
    return this.props.isWhitelistMintEnabled && this.props.isUserInWhitelist;
  }

  private incrementMintAmount(): void {
    this.setState({
      mintAmount: Math.min(
        this.props.maxMintAmountPerTx,
        this.state.mintAmount + 1
      ),
    });
  }

  private decrementMintAmount(): void {
    this.setState({
      mintAmount: Math.max(1, this.state.mintAmount - 1),
    });
  }

  private async mint(): Promise<void> {
    if (!this.props.isPaused) {
      await this.props.mintTokens(this.state.mintAmount);

      return;
    }

    await this.props.whitelistMintTokens(this.state.mintAmount);
  }

  render() {
    return (
      <>
        {this.canMint() ? (
          <div
            className={`mint-widget ${
              this.props.loading
                ? "animate-pulse saturate-0 pointer-events-none"
                : ""
            }`}
          >
            <PriceContainer>
              <strong>Total price:</strong>{" "}
              <Price>
                {utils.formatEther(
                  this.props.tokenPrice.mul(this.state.mintAmount)
                )}{" "}
                {this.props.networkConfig.symbol}
              </Price>
            </PriceContainer>

            <Controls>
              <AmountActionContainer>
                <DecrementButton
                  className="decrease"
                  disabled={this.props.loading}
                  onClick={() => this.decrementMintAmount()}
                />
                <TotalToMint>{this.state.mintAmount}</TotalToMint>
                <IncrementButton
                  className="increase"
                  disabled={this.props.loading}
                  onClick={() => this.incrementMintAmount()}
                />
              </AmountActionContainer>
              <MintButton
                className="primary"
                disabled={this.props.loading}
                onClick={() => this.mint()}
              >
                <span>MINT</span>
              </MintButton>
            </Controls>
          </div>
        ) : (
          <CannotMint>
            <span className="emoji">⏳</span>{" "}
            {this.props.isWhitelistMintEnabled ? (
              <>
                You are not included in the <strong>whitelist</strong>.
              </>
            ) : (
              <>
                The contract is <strong>paused</strong>.
              </>
            )}
            <br />
            Please come back during the next sale!
          </CannotMint>
        )}
      </>
    );
  }
}
