// The questions and answers for the FAQ section.
const faqData = {
	rows: [
		{
			title: 'What is the mint cost?',
			content:
				'0.06 ETH',
		},	
		{
			title: 'What is the max number of mints in a single transaction for whitelist?',
			content:
				'3 per each whitelist entry',
		},			
		{
			title: 'What is the max number of mints in a single transaction for public sale?',
			content:
				'5',
		},			
		{
			title: 'How do I mint?',
			content:
				`1. Connect your MetaMask wallet to our site by clicking on the "CONNECT Wallet" button.
				\n2. After connecting your wallet, select the number of BountyBotz you would like to mint, and click the ‘MINT’ button.
				\n3. Once you have minted your BountyBotz, check your MetaMask wallet for your transaction details to verify a successful transaction.
				\n4. Click on the ‘VIEW ON OPENSEA’ button to view your BountyBotz on Opensea. Your NFT will be revealed within 24 hours of the collection 100% minted out.`
				,
		},					
		{
			title: 'What time can whitelist winners mint?',
			content:
				'20:00 PM UTC, Monday September 12, 2022',
		},
		{
			title: 'What time is the public sale mint?',
			content:
				'02:00 AM UTC, Tuesday September 13, 2022',
		},
		{
			title:
				'What intellectual property rights do BountyBotz owners possess?',
			content:
				'BountyBotz owners receive full commercial art rights to the BountyBot they hold.',
		},
		{
			title:
				'How will the 5% secondary sale fee be utilized?',
			content:
				'The 5% secondary sale fee will be used to invest into delivering against the BountyBotz roadmap, paying artists/illustrators, and funding operations.',
		},		
		{
			title:
				'Where will the BountyBotz PFPs be stored?',
			content:
				'IPFS',
		},		
		{
			title:
				'When will the BountyBotz NFTs be revealed?',
			content:
				'Within 24 hours of selling out the 6,969 NFT mints.',
		},				
	],
};

// The styles for the FAQ components.
const faqStyles = {
	bgColor: 'transparent',
	rowTitleColor: 'var(--white-gray)',
	arrowColor: 'var(--white-gray)',
	rowContentColor: 'var(--white)',
	rowTitleTextSize: 'var(--font-medium)',
	rowContentTextSize: 'var(--font-small)',
	rowContentPaddingBottom: '24px',
};

const faqConfig = {
	title: 'FAQs',
	data: faqData,
	styles: faqStyles,
};

export default faqConfig;
