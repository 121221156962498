//let phaseOne = "Hellow World!\n test";

const roadmapConfig = {
	title: 'Long-Term Roadmap',
	content: [
		{
			title: '01 Launch the BountyBotz Community',
			content: 'Mint 6969 BountyBot NFTs on the Ethereum Blockchain (Sept-2022)',
		},
		{
			title: '02 Release the 30 captured BountyBotz',
			content: '1 promotional BountyBot will be airdropped to a random holder every day for 30 consecutive days (Sept-2022)',
		},
		{
			title: '03 Render 3D Playable Avatars for Otherside Metaverse',
			content: 'Each BountyBotz holder will be airdropped their PFP as a 3D Playable Avatar for the Otherside Metaverse Game. BountyBotz will be illustrated and rendered through Otherside provided SDK tools (Q1-2023)',
		},
		{
			title: '04 Enable NFT Staking Rewards',
			content: 'Allow for BountyBotz holders to swap $BOTZ tokens for various rewards over time. Holders will also receive opportunities to P2E with their Metaverse Avatars (Q1-2023)',
		},
		{
			title: '05 Brand Building, Partnerships, & Events',
			content: 'BountyBotz will continue growing the community by partnering with web3 builders to create additional utility for holders, generating brand awareness/publicity, and collaborating through in-person events (Q2-2023)',
		},
		{
			title: '06 Open the BountyBotz Merch Store',
			content: 'Reward holders with limited edition shirts, hoodies, and other merch (Q1-2023)',
		}
	]		
}

//let phaseOne = "Hellow World!\n test";

//const roadmapConfig = {
//	rows: [
//		{
//			title: 'Roadmap',
//			content: `Phase 2: BountyBotz will be rendered into 3D Avatars for the Otherside Metaverse Game by Yuga Labs.` 
//		},
//		{
//			//title: 'Roadmap 2',
//			content: `Phase 2: BountyBotz will be rendered into 3D Avatars for the Otherside Metaverse Game by Yuga Labs.` 
//		},		
//	],
//};
//export default roadmapConfig;


export default roadmapConfig;
