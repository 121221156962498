import nft001 from '../images/nfts/001.webp';
import nft002 from '../images/nfts/002.webp';
import nft008 from '../images/nfts/008.webp';
import nft069 from '../images/nfts/069.webp';
import Nftmagenta from '../images/nfts/magenta.webp';
import nft657 from '../images/nfts/657.webp';
import nft597 from '../images/nfts/597.webp';
import nft444 from '../images/nfts/444.webp';
//import nft407 from '../images/nfts/407.webp';
import nft1506 from '../images/nfts/1506.webp';

const artworkConfig = {
	title: 'ARTWORK',
	images: [
		nft1506,
		nft069,
		Nftmagenta,
		nft002,
		nft444,
		nft001,
		nft008,
		nft657,
		nft597,
		//		nft407,
	],
};

export default artworkConfig;
