import React from "react";
import styled from "styled-components";
import "./Team.css";
import TEAM_CONFIG from "../../configs/team-config";

import Section from "../../components/section";
import TeamMember from "../../components/team-member";

function renderTeamMember(data, i) {
  return (
    <TeamMember
      name={data.name}
      title={data.title}
      socials={data.socials}
      description={data.description}
      key={i}
    >
      <img
        width="100%"
        src={data.image}
        alt={data.altText}
        className={data.classList}
      />
    </TeamMember>
  );
}

const TeamWrapper = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 16px;

  @media (max-width: 480px) {
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 6px;
  }
`;

function TeamView() {
  return (
    <Section name="Team">
      <h2 className="title text-center">{TEAM_CONFIG.title}</h2>
      <TeamWrapper>{TEAM_CONFIG.data.map(renderTeamMember)}</TeamWrapper>
    </Section>
  );
}

export default TeamView;

// section wrapper being forced too big
// set its overflow: hidden

// make it in a media query a certain width for mobile
// make the child ('Team section') width 100%
// with overflow: scroll
// then the child should just in the parent & scroll in the mobile
