import React from "react";
import { ethers, BigNumber } from "ethers";
import { ExternalProvider, Web3Provider } from "@ethersproject/providers";
import detectEthereumProvider from "@metamask/detect-provider";
import NftContractType from "../lib/NftContractType";
import CollectionConfig from "../../smart-contract/config/CollectionConfig";
import NetworkConfigInterface from "../../smart-contract/lib/NetworkConfigInterface";
import CollectionStatus from "./CollectionStatus";
import MintWidget from "./MintWidget";
import Whitelist from "../lib/Whitelist";
import { toast } from "react-toastify";
import styled from "styled-components";
import { keyframes } from "styled-components";

const ContractAbi = require("../../smart-contract/artifacts/contracts/" +
  CollectionConfig.contractName +
  ".sol/" +
  CollectionConfig.contractName +
  ".json").abi;

interface Props {}

interface State {
  userAddress: string | null;
  network: ethers.providers.Network | null;
  networkConfig: NetworkConfigInterface;
  totalSupply: number;
  maxSupply: number;
  maxMintAmountPerTx: number;
  tokenPrice: BigNumber;
  isPaused: boolean;
  loading: boolean;
  isWhitelistMintEnabled: boolean;
  isUserInWhitelist: boolean;
  merkleProofManualAddress: string;
  merkleProofManualAddressFeedbackMessage: string | JSX.Element | null;
  errorMessage: string | JSX.Element | null;
}

const defaultState: State = {
  userAddress: null,
  network: null,
  networkConfig: CollectionConfig.mainnet,
  totalSupply: 0,
  maxSupply: 0,
  maxMintAmountPerTx: 0,
  tokenPrice: BigNumber.from(0),
  isPaused: true,
  loading: false,
  isWhitelistMintEnabled: false,
  isUserInWhitelist: false,
  merkleProofManualAddress: "",
  merkleProofManualAddressFeedbackMessage: null,
  errorMessage: null,
};

const Container = styled.div`
  text-align: center;
`;

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const loaderAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }  
  25% {
    transform: rotate(180deg);
  }  
  50% {
    transform: rotate(180deg);
  }  
  75% {
    transform: rotate(360deg);
  }  
  100% {
    transform: rotate(360deg);
  }
`;

const loaderInnerAnim = keyframes`
  0% {
    height: 0%;
  }  
  25% {
    height: 0%;
  }  
  50% {
    height: 100%;
  }  
  75% {
    height: 100%;
  }  
  100% {
    height: 0%;
  }
`;

const Loader = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #fff;
  top: 50%;
  animation: ${loaderAnim} 2s infinite ease;
`;

const LoaderInner = styled.span`
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: ${loaderInnerAnim} 2s infinite ease-in;
`;

const ConnectButton = styled.button`
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin: 16px auto;

  span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    width: 100%;
    height: 100%;
    transition: 300ms;
  }

  &:active,
  &:hover {
    outline: 0;
  }

  &:hover {
    span {
      background: none;
    }
  }
`;

const Error = styled.div`
  color: red;
`

export default class Dapp extends React.Component<Props, State> {
  provider!: Web3Provider;

  contract!: NftContractType;

  private merkleProofManualAddressInput!: HTMLInputElement;

  constructor(props: Props) {
    super(props);

    this.state = defaultState;
  }

  componentDidMount = async () => {
    const browserProvider =
      (await detectEthereumProvider()) as ExternalProvider;

    if (browserProvider?.isMetaMask !== true) {
      this.setError(
        <>
          We were not able to detect <strong>MetaMask</strong>. We value{" "}
          <strong>privacy and security</strong> a lot so we limit the wallet
          options on the DAPP.
        </>
      );
    }

    this.provider = new ethers.providers.Web3Provider(browserProvider);

    this.registerWalletEvents(browserProvider);

    await this.initWallet();
  };

  async mintTokens(amount: number): Promise<void> {
    try {
      this.setState({ loading: true });
      const transaction = await this.contract.mint(amount, {
        value: this.state.tokenPrice.mul(amount),
      });

      toast.info(
        <>
          Transaction sent! Please wait...
          <br />
          <a
            href={this.generateTransactionUrl(transaction.hash)}
            target="_blank"
            rel="noopener"
          >
            View on {this.state.networkConfig.blockExplorer.name}
          </a>
        </>
      );

      const receipt = await transaction.wait();

      toast.success(
        <>
          Success!
          <br />
          <a
            href={this.generateTransactionUrl(receipt.transactionHash)}
            target="_blank"
            rel="noopener"
          >
            View on {this.state.networkConfig.blockExplorer.name}
          </a>
        </>
      );

      this.refreshContractState();
      this.setState({ loading: false });
    } catch (e) {
      this.setError(e);
      this.setState({ loading: false });
    }
  }

  async whitelistMintTokens(amount: number): Promise<void> {
    try {
      this.setState({ loading: true });
      const transaction = await this.contract.whitelistMint(
        amount,
        Whitelist.getProofForAddress(this.state.userAddress!),
        { value: this.state.tokenPrice.mul(amount) }
      );

      toast.info(
        <>
          Transaction sent! Please wait...
          <br />
          <a
            href={this.generateTransactionUrl(transaction.hash)}
            target="_blank"
            rel="noopener"
          >
            View on {this.state.networkConfig.blockExplorer.name}
          </a>
        </>
      );

      const receipt = await transaction.wait();

      toast.success(
        <>
          Success!
          <br />
          <a
            href={this.generateTransactionUrl(receipt.transactionHash)}
            target="_blank"
            rel="noopener"
          >
            View on {this.state.networkConfig.blockExplorer.name}
          </a>
        </>
      );

      this.refreshContractState();
      this.setState({ loading: false });
    } catch (e) {
      this.setError(e);
      this.setState({ loading: false });
    }
  }

  private isWalletConnected(): boolean {
    return this.state.userAddress !== null;
  }

  private isContractReady(): boolean {
    return this.contract !== undefined;
  }

  private isSoldOut(): boolean {
    return (
      this.state.maxSupply !== 0 &&
      this.state.totalSupply >= this.state.maxSupply
    );
  }

  private isNotMainnet(): boolean {
    return (
      this.state.network !== null &&
      this.state.network.chainId !== CollectionConfig.mainnet.chainId
    );
  }

  private copyMerkleProofToClipboard(): void {
    const merkleProof = Whitelist.getRawProofForAddress(
      this.state.userAddress ?? this.state.merkleProofManualAddress
    );

    if (merkleProof.length < 1) {
      this.setState({
        merkleProofManualAddressFeedbackMessage:
          "The given address is not in the whitelist, please double-check.",
      });

      return;
    }

    navigator.clipboard.writeText(merkleProof);

    this.setState({
      merkleProofManualAddressFeedbackMessage: (
        <>
          <strong>Congratulations!</strong> <span className="emoji">🎉</span>
          <br />
          Your Merkle Proof <strong>has been copied to the clipboard</strong>.
          You can paste it into{" "}
          <a href={this.generateContractUrl()} target="_blank">
            {this.state.networkConfig.blockExplorer.name}
          </a>{" "}
          to claim your tokens.
        </>
      ),
    });
  }

  render() {
    return (
      <Container>
        {this.state.errorMessage ? (
          <Error>
            <p>{this.state.errorMessage}</p>
          </Error>
        ) : null}

        {this.isWalletConnected() ? (
          <WidgetContainer>
            {this.isContractReady() ? (
              <>
                {!this.isSoldOut() ? (
                  <MintWidget
                    networkConfig={this.state.networkConfig}
                    maxSupply={this.state.maxSupply}
                    totalSupply={this.state.totalSupply}
                    tokenPrice={this.state.tokenPrice}
                    maxMintAmountPerTx={this.state.maxMintAmountPerTx}
                    isPaused={this.state.isPaused}
                    isWhitelistMintEnabled={this.state.isWhitelistMintEnabled}
                    isUserInWhitelist={this.state.isUserInWhitelist}
                    mintTokens={(mintAmount) => this.mintTokens(mintAmount)}
                    whitelistMintTokens={(mintAmount) =>
                      this.whitelistMintTokens(mintAmount)
                    }
                    loading={this.state.loading}
                  />
                ) : (
                  <div className="collection-sold-out">
                    <h2>
                      Tokens have been <strong>sold out</strong>!{" "}
                      <span className="emoji">🥳</span>
                    </h2>
                    You can buy from our beloved holders on{" "}
                    <a href={this.generateMarketplaceUrl()} target="_blank">
                      {CollectionConfig.marketplaceConfig.name}
                    </a>
                    .
                  </div>
                )}
                <CollectionStatus
                  userAddress={this.state.userAddress}
                  maxSupply={this.state.maxSupply}
                  totalSupply={this.state.totalSupply}
                  isPaused={this.state.isPaused}
                  isWhitelistMintEnabled={this.state.isWhitelistMintEnabled}
                  isUserInWhitelist={this.state.isUserInWhitelist}
                  isSoldOut={this.isSoldOut()}
                />
              </>
            ) : (
              <Loader>
                <LoaderInner />
              </Loader>
            )}
          </WidgetContainer>
        ) : (
          <div className="no-wallet">
            {!this.isWalletConnected() ? (
              <ConnectButton
                className="primary"
                disabled={this.provider === undefined}
                onClick={() => this.connectWallet()}
              >
                <span>Connect Wallet</span>
              </ConnectButton>
            ) : null}
          </div>
        )}
      </Container>
    );
  }

  private setError(error: any = null): void {
    let errorMessage = "Unknown error...";

    if (null === error || typeof error === "string") {
      errorMessage = error;
    } else if (typeof error === "object") {
      // Support any type of error from the Web3 Provider...
      if (error?.error?.message !== undefined) {
        errorMessage = error.error.message;
      } else if (error?.data?.message !== undefined) {
        errorMessage = error.data.message;
      } else if (error?.message !== undefined) {
        errorMessage = error.message;
      } else if (React.isValidElement(error)) {
        this.setState({ errorMessage: error });

        return;
      }
    }

    this.setState({
      errorMessage:
        null === errorMessage
          ? null
          : errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1),
    });
  }

  private generateContractUrl(): string {
    return this.state.networkConfig.blockExplorer.generateContractUrl(
      CollectionConfig.contractAddress!
    );
  }

  private generateMarketplaceUrl(): string {
    return CollectionConfig.marketplaceConfig.generateCollectionUrl(
      CollectionConfig.marketplaceIdentifier,
      !this.isNotMainnet()
    );
  }

  private generateTransactionUrl(transactionHash: string): string {
    return this.state.networkConfig.blockExplorer.generateTransactionUrl(
      transactionHash
    );
  }

  private async connectWallet(): Promise<void> {
    try {
      await this.provider.provider.request!({ method: "eth_requestAccounts" });

      this.initWallet();
    } catch (e) {
      this.setError(e);
    }
  }

  private async refreshContractState(): Promise<void> {
    this.setState({
      maxSupply: (await this.contract.maxSupply()).toNumber(),
      totalSupply: (await this.contract.totalSupply()).toNumber(),
      maxMintAmountPerTx: (await this.contract.maxMintAmountPerTx()).toNumber(),
      tokenPrice: await this.contract.cost(),
      isPaused: await this.contract.paused(),
      isWhitelistMintEnabled: await this.contract.whitelistMintEnabled(),
      isUserInWhitelist: Whitelist.contains(this.state.userAddress ?? ""),
    });
  }

  private async initWallet(): Promise<void> {
    const walletAccounts = await this.provider.listAccounts();

    this.setState(defaultState);

    if (walletAccounts.length === 0) {
      return;
    }

    const network = await this.provider.getNetwork();
    let networkConfig: NetworkConfigInterface;

    if (network.chainId === CollectionConfig.mainnet.chainId) {
      networkConfig = CollectionConfig.mainnet;
    } else if (network.chainId === CollectionConfig.testnet.chainId) {
      networkConfig = CollectionConfig.testnet;
    } else {
      this.setError("Unsupported network!");

      return;
    }

    this.setState({
      userAddress: walletAccounts[0],
      network,
      networkConfig,
    });

    if (
      (await this.provider.getCode(CollectionConfig.contractAddress!)) === "0x"
    ) {
      this.setError(
        "Could not find the contract, are you connected to the right chain?"
      );

      return;
    }

    this.contract = new ethers.Contract(
      CollectionConfig.contractAddress!,
      ContractAbi,
      this.provider.getSigner()
    ) as unknown as NftContractType;

    this.refreshContractState();
  }

  private registerWalletEvents(browserProvider: ExternalProvider): void {
    // @ts-ignore
    browserProvider.on("accountsChanged", () => {
      this.initWallet();
    });

    // @ts-ignore
    browserProvider.on("chainChanged", () => {
      window.location.reload();
    });
  }
}
