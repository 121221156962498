import React from "react";
import Particles from "react-tsparticles";
import "./Hero.css";
import Socials from "../../components/socials";
import particlesConfig from "./particlesConfig";
import HERO_CONFIG from "../../configs/hero-config";
import styled from "styled-components";

const Container = styled.div`
`;

function HeroView() {
  return (
    <Container>
      <div className="Hero">
        {/* <Particles canvasClassName="particles" options={particlesConfig} /> */}
        <div className="socials-wrapper z-2">
          <Socials />
        </div>
        {HERO_CONFIG.title && (
          <h1 className="hero-title m-b-28 z-2">{HERO_CONFIG.title}</h1>
        )}
        {HERO_CONFIG.subtitle && (
          <p className="hero-subtitle">{HERO_CONFIG.subtitle}</p>
        )}
        {HERO_CONFIG.buttonText && (
          <a
            className="hero-link z-2"
            href={HERO_CONFIG.buttonLink}
            target="_blank"
            rel="noreferrer"
          >
            {HERO_CONFIG.buttonText}
          </a>
        )}
      </div>
    </Container>
  );
}

export default HeroView;
