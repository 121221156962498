import React from 'react';
import './Legend.css';
import LEGENDS_CONFIG from '../../configs/legends-config';

import Section from '../../components/section';
import LegendMember from '../../components/legend-member';

function renderLegendMember(data, i) {
	return (
		//<LegendMember name={data.name} title={data.title} key={i}>
		<LegendMember name={data.name} key={i}>		
			<img width="100%" src={data.image} alt={data.altText} className={data.classList} />
		</LegendMember>
	);
}

function LegendView() {
	return (
		<Section name="Legend">
			<h2 className="title text-center">{LEGENDS_CONFIG.title}</h2>
			<div className="legend-wrapper">
				{LEGENDS_CONFIG.data.map(renderLegendMember)}
			</div>
		</Section>
	);
}

export default LegendView;
//<h2 className="title text-center">{LEGENDS_CONFIG.title}</h2> line 20
