const cssVariableOverrides = [
	{
		name: '--primary',
		value: '#41a0b6',
	},
	{
		name: '--primary-hover',
		value: '#43afc8',
	},
	{
		name: '--white',
		value: '#fefefe',
	},
	{
		name: '--white-gray',
		value: '#c2c2c2',
	},
	{
		name: '--black',
		value: '#030303',
	},
	{
		name: '--font-large',
		value: '48px',
	},
	{
		name: '--font-medium',
		value: '28px',
	},
	{
		name: '--font-small',
		value: '21px',
	},
];

export default cssVariableOverrides;
