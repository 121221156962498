import React from 'react';
import './LegendMember.css';

function LegendMember({ name, title, children }) {
	return (
		<div className="LegendMember">
			<div className="name-wrapper">
				<div>{children}</div>
				<h3 className="legend-title text-center">
					{name}
				</h3>
			</div>
		</div>
	);
}

export default LegendMember;

//					{name} ({title})